.login-form {
  justify-content: center;
  min-height: 90vh;
}

.button-block {
  width: 100%;
}

.login-background {
  justify-content: center;
  min-height: 40vh;
  padding: 2rem;
}

.forgotPass{
  display:block;
  text-align:right;
  text-decoration:none;
  margin-top: 10px;
}

.brand_name{
  color: #fff;
  text-decoration: none;
}