@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dur: 0.3s;
  --color-dark: #57bd84;
  --font-fam: "Lato", sans-serif;
  --btn-width: 6rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
  --rad: 10px;
  --height:60px;
  --fontSize: 1.8rem;
}

section.home {
  height: 100vh;
  background: url(/static/media/himalayas.3530bc7d.jpg) no-repeat;
  background-size: cover;
}

.home-title {
  color: var(--head);
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary);
  font-size: 4rem;
}

.fa-search {
  color: var(--primary);
  font-size: 1.4rem;
  cursor: pointer;
}

@media screen and (max-width:800px) {
  .home-title{
    font-size: 3rem;
  }

  :root{
    --height:50px;
    --fontSize: 1.5rem;
  }
}

@media screen and (max-width:600px) {
  .home-title{
    font-size: 2.5rem;
    text-align: center;
  }
}


section.home form {
  position: relative;
  width: 90%;
  max-width: 750px;
  background: var(--primary);
  border-radius: 10px;
  border-radius: var(--rad);
  margin-top: 1rem;
}

section.home input,
.searchIcon {
  height: 60px;
  height: var(--height);
  font-family: "Lato", sans-serif;
  font-family: var(--font-fam);
  border: none;
  color: var(--font);
  font-size: 1.8rem;
  font-size: var(--fontSize);
}

section.home input[type="search"] {
  outline: none;
  width: 100%;
  background: var(--primary);
  padding: 0 20px;
  border-radius: 10px;
  border-radius: var(--rad);
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.3s cubic-bezier(0, 0, 0.43, 1.49);
  transition: all var(--dur) var(--bez);
  transition-property: width, border-radius;
  z-index: 1;
  position: relative;
}

section.home button {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 6rem;
  width: var(--btn-width);
  font-weight: bold;
  background: #57bd84;
  background: var(--color-dark);
  border-radius: 0 10px 10px 0;
  border-radius: 0 var(--rad) var(--rad) 0;
}

section.home input:not(:-ms-input-placeholder) {
  border-radius: 10px 0 0 10px;
  border-radius: var(--rad) 0 0 var(--rad);
  width: calc(100% - 6rem);
  width: calc(100% - var(--btn-width));
}

section.home input:not(:placeholder-shown) {
  border-radius: 10px 0 0 10px;
  border-radius: var(--rad) 0 0 var(--rad);
  width: calc(100% - 6rem);
  width: calc(100% - var(--btn-width));
}
section.home input:not(:-ms-input-placeholder) + button {
  display: block;
}
section.home input:not(:placeholder-shown) + button {
  display: block;
}

section.home label {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

:root{
    --primary: #fff;
    --secondary: rgba(255, 255, 255, 0.699);
    --ternary: black;
    --button:#fe4545;
}

section.destination{
    width:100%;
    text-align: center;
    padding: 2rem 0;
}

section.destination h1{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    margin-top: 3rem;
}

.destination-container{
    display: flex;
    max-width: 1000px;
    width: 90%;
    margin:0 auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.destination-box{
    margin:10px 5px;
    height: 400px;
    padding: 1rem;
    width: 300px;
    box-shadow: -1px -2px 1px #706c6c73, 3px 3px 7px rgba(80, 82, 87, 0.288);
    background-color: #fff;
    background-color: var(--primary);
    text-decoration: none;
    color: inherit;
}

.destination-box:nth-child(3){
    margin-bottom: 2rem;
}

.destination-box h4{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    margin-top: 1rem;
}

.img-container{
    width:100%;
    height:200px;
    overflow: hidden;
}

.destination-box img{
    min-width: 100%;
    min-height: 100%;
    width:20px;
}

.destination-box p{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: lighter;
    text-align: left;
}

.more-places-btn{
    padding: 10px 25px;
    border-radius: 5px;
    background-color: var(--head);
    color: #fff;
    color: var(--primary);
    cursor: pointer;
    text-decoration: none;
    margin-top:1rem;
}
section.accomodation{
    padding: 3rem 0;
}

.accomodation-container{
    display: flex;
    align-items: center;
    max-width: 1000px;
    width: 90%;
    margin: 1rem auto;
}

@media screen and (max-width:700px) {
    .accomodation-container{
        flex-direction: column;
        max-width: 400px;
    }

    .accomodation-container:nth-child(2){
        flex-direction: column-reverse;
    }
}

.image-container-accomodation{
    min-width: 400px;
    max-width: 400px;
    height: 250px;
    overflow: hidden;
}


.accomodation-img{
    min-height: 100%;
    min-width: 100%;
    width: 10px;
}

.accomodation-box{
    padding: 1rem;
}

.accomodation-container h3{
    font-size: 2.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
}

.accomodation-container p{
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 1.2rem;
    margin-top: 1.5rem;
}

.accomodation-btn{
    font-size: 1rem;
    padding: 10px 25px;
    border-radius: 5px;
    background-color: var(--font);
    color: var(--primary);
    cursor: pointer; 
    margin-top: 1rem;  
    border: none;
}

@media screen and (max-width:500px) {
    .image-container-accomodation{
        min-width: 95%;
        max-width: 400px;
        height: 200px;
        overflow: hidden;
    }

    .accomodation-container h3{
        font-size: 2rem;
    }

    .accomodation-container p{
        margin-top: 0;
    }
}
.bottom-box{
    width: 90%;
    margin: 0 auto;
    max-width: 900px;
    text-align: center;
}
.bottom-box h1{
    position: relative;
    margin: 1rem 0;
    font-size: 3rem;
}
.bottom-box h1::after{
    content: "";
    display: block;
    width: 125px;
    height: 2px;
    border: 1px solid var(--head);
    background-color: var(--head);
    position: absolute;
    top: 100%;
}

.bottom-box .about-nepal{
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-family: "Roboto",sans-serif;
    font-weight: normal;
}

.bottom-box .learn-more-btn{
    padding: 10px 25px;
    border-radius: 5px;
    background-color: var(--font);
    color: var(--primary);
    text-decoration: none;
}

.bottom-box .learn-more-btn i{
    transition: .2s ease;
}


.bottom-box .learn-more-btn:hover i{
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
}

.medias{
    width: 90%;
    margin: 0 auto;
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width:800px) {
    .medias{
        flex-direction: column-reverse;
        text-align: center;
    }

    .subscribe{
        margin:.5rem 0;
    }

    .medias .links{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .medias .links a{
        margin:5px;
    }
}

.medias .links{
    font-family: 'Roboto' , sans-serif;
}

.medias .links a{
    font-size: 1rem;
    color: var(--font);
    text-decoration: none;
    display: block;
}

.subscribe h1{
    font-size: 1.8rem;
}

.subscribe .input-wrap{
    height:40px;
    max-width:400px;
    width: 100%;
    box-shadow:0px 0px 3px royalblue;
    display:flex;
    overflow: hidden;
    border-radius: 5px;
    margin: 0.5rem auto;
}

.subscribe .input-wrap input{
    height: 100%;
    padding: 5px 10px;
    outline:none;
    border:none;
    flex:1 1;
}

.subscribe button{
    background-color: var(--font);
    color: var(--primary);
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1rem;
    border: none;
}

.social svg{
    font-size: 40px;
    margin: 5px;
}

.social svg:hover{
    color: red;
}

.copyright{
    display: flex;
    background-color: #eee;
    padding: 10px;
    justify-content: space-between;
}

.copyright span{
    font-family: 'Roboto', sans-serif;
    color: var(--head);
    font-size: 1rem;
}

.link-box a{
    text-decoration: none;
    margin:0px 7px;
}

@media screen and (max-width:500px) {
    .bottom-box h1{
        font-size: 2.2rem;
    }

    .bottom-box .about-nepal{
        font-size: 1rem;
    }

    .bottom-box h1::after{
        width: 100px;
    }

    .medias{
        text-align: left;
    }

    .subscribe{
        margin:.5rem 0;
    }

    .medias .links{
        flex-direction: column;
    }

    .medias .links a{
        margin:0;
    }

    .subscribe h1{
        font-size: 1.75rem;
        line-height: 1.75rem;
    }

    .copyright{
        flex-direction: column-reverse;
        text-align: center;
    }
}
.intro-section{
    width: 90%;
    max-width:1000px;
    margin:0 auto;
    padding: 2rem 0;
}

.intro-section .main-info{
    display: flex;
    align-items: center;
    margin:1rem 0;
}

.intro-section img{
    max-height: 400px;
    max-width: 500px;
    width:90%;
}

.intro-section .info{
    padding: 2rem;
}

.info h1{
    font-family: 'Oswald', sans-serif;
    font-size: 5rem;
}
.info-txt{
    font-family: 'Roboto',sans-serif;
    font-size: 1.2rem;
}
.sources{
    margin-top: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: bold;
}
.sources a{
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}
.info-box-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.info-bar{
    border: 2px solid black;
    padding: 5px 0px 5px 10px;
    width: 200px;
    margin:10px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}

.info-text{
    font-size: 0.75rem;
    display: block;
}

.info-text1{
    font-size: 1.2rem;
    font-weight:900;
}

@media screen and (max-width:900px) {
    .main-info{
        flex-direction: column;
    }
}

@media screen and (max-width:500px) {
    .info-txt{
        font-size:1rem;
    }

    .info h1{
        font-size: 3rem;
    }

    .info-bar{
        width: 100%;
    }
}
.place {
  --primary: #003893;
}

.placeDetails {
  padding: 1rem;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  font-family: "Roboto";
}

.placeDetails__interact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.placeDetails__interact .name {
  font-size: 2rem;
}

.placeDetails__interact .column2 {
  display: flex;
}

.placeDetails__interact button {
  padding: 5px 15px;
  border: 1px solid var(--primary);
  border-radius: 25px;
  cursor: pointer;
  background: transparent;
  color: var(--primary);
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
}

.placeDetails__interact button .MuiSvgIcon-root {
  font-size: 1rem;
  padding-left: 2px;
}

.placeDetails__visualInfo {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.placeDetails__visualInfo .imageContainer {
  max-width: 620px;
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  margin: 5px;
}

.placeDetails__visualInfo .imageContainer img {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .placeDetails__visualInfo .imageContainer img {
    height: 100%;
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .placeDetails__interact {
    display: block;
  }

  .placeDetails__visualInfo {
    flex-direction: column;
  }

  .placeDetails__visualInfo .imageContainer img {
    height: auto;
    width: 100%;
  }

  .placeDetails__visualInfo .physicalDetails .map {
    width: 100%;
  }

  .placeDetails__interact .column2 {
    justify-content: right;
  }
}

.placeDetails__visualInfo .physicalDetails {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0 0 3px #003893);
          filter: drop-shadow(0 0 3px #003893);
  margin: 5px;
}

.placeDetails__visualInfo .physicalDetails .map {
  margin: 10px 0;
}

.placeDetails__visualInfo .physicalDetails p {
  margin-top: 2px;
}

.placeDetails__moreInformation {
  margin: 1rem 0;
}

.placeDetails__moreInformation p {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.place .loader{
  position: absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.place .error{
  color: red;
  position: absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width: 100%;
  text-align: center;
}
nav {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  padding: 0 2rem;
}

.logo {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: var(--primary);
  text-decoration: none;
  position: relative;
}

.logo::before{
  content: "";
  position: absolute;
  bottom:2px;
  left:0;
  width:68%;
  height:3px;
  background-color: var(--primary);
}

.logo::after{
  content: "";
  position: absolute;
  bottom:2px;
  right:-5px;
  width:26%;
  height:3px;
  background-color: var(--primary);
}

.link {
  color: var(--primary);
  padding: 0 10px;
  margin: 0 5px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  text-decoration: none;
  transition: .3s ease-in-out;
}

.link:hover {
  opacity: 0.9;
}

.link-border{
  padding: 5px 15px;
  border: 1px solid var(--primary);
  border-radius: 25px;
}

.link-border:hover{
  color: royalblue;
  background-color: var(--primary);
}

nav .MuiSvgIcon-root{
  color: var(--primary);
  font-size: 2rem;
  cursor: pointer;
  z-index: 99;
  display: none;
}

@media screen and (max-width:800px) {

  nav{
    padding: 0 1rem;
  }

  nav .MuiSvgIcon-root{
    display: block;
  }
  
  nav .list{
    background-color: var(--color-dark);
    padding: 3rem 5rem 1rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    transition: 50ms ease-in-out;
  }

  nav .list a{
    display: block;
    margin:.5rem 0;
  }

  nav .list.active{
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  nav .list .link-border{
    border: none;
    padding: 0 10px;
  }
}
.login-form {
  justify-content: center;
  min-height: 90vh;
}

.button-block {
  width: 100%;
}

.login-background {
  justify-content: center;
  min-height: 40vh;
  padding: 2rem;
}

.forgotPass{
  display:block;
  text-align:right;
  text-decoration:none;
  margin-top: 10px;
}

.brand_name{
  color: #fff;
  text-decoration: none;
}
:root{
    --primary: #fff;
    --head: #DC143C;
    --font: #003893;
    --ternary: rgb(7, 7, 7);
    --background: red;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
}
.container{
    min-height: 100vh;
    display: flex;
}
.flex{
    display: flex;
}
.center{
    align-items: center;
    justify-content: center;
}
.column{
    flex-direction: column; 
}
.space-around{
    justify-content: space-around;
}
.middle{
    justify-content: center;
}




.festivals-intro {
  min-height: 100vh;
  background-size: cover;
  background: -webkit-linear-gradient(
      top,
      rgba(4, 21, 34, 0.5),
      rgba(88, 87, 31, 0.6)
    ),
    url(/static/media/festivals.7d781f8c.jpg) no-repeat;
  background: url(/static/media/festivals.7d781f8c.jpg) no-repeat;
  background: -moz-linear-gradient(
      top,
      rgba(4, 21, 34, 0.5),
      rgba(88, 87, 31, 0.6)
    ),
    url(/static/media/festivals.7d781f8c.jpg) no-repeat;
}
.festivals-intro .festivals-main{
  position: absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  max-width: 1200px;
  width: 95%;
}

.festivals-intro .festivals-main h1{
  padding: 5px 15px;
  font-size: 2.5rem;
  position: relative;
  color: white;
}

.festivals-intro .festivals-main h1:after{
  background-color: tomato;
  content: "";
  position: absolute;
  top:0;
  left: 0;
  height: 100%;
  width: 375px;
  max-width: 100%;
  z-index: -1;
  -webkit-animation: revealh1 .5s linear;
          animation: revealh1 .5s linear;
}

@-webkit-keyframes revealh1 {
  from{
    width:0px;
  }
  to{
    width: 375px;
  }
}

@keyframes revealh1 {
  from{
    width:0px;
  }
  to{
    width: 375px;
  }
}

.festivals-intro .festivals-main p{
  padding: 3px 10px;
  font-size: 1.5rem;
  position: relative;
  color: white;
  margin-top: 5px;
}

.festivals-intro .festivals-main p:after{
  background-color: royalblue;
  content: "";
  position: absolute;
  top:0;
  left: 0;
  height: 100%;
  width: 67ch;
  max-width: 100%;
  z-index: -1;
  -webkit-animation: revealp .5s linear .5s;
          animation: revealp .5s linear .5s;
}

@-webkit-keyframes revealp {
  from{
    width:0px;
  }
  to{
    width: 67ch;
  }
}

@keyframes revealp {
  from{
    width:0px;
  }
  to{
    width: 67ch;
  }
}

.festivals-intro .festivals-main p span{
  font-weight: bold;
}

@media screen and (max-width: 400px) {
  .festivals-intro .festivals-main h1{
    font-size: 2rem;
  }

  .festivals-intro .festivals-main p{
    font-size: 1rem;
  }
}


section.festivals {
  padding: 2rem 0;
  max-width: 1100px;
  margin: 1rem auto;
}

.festival-container {
  margin: 2rem auto;
  display: flex;
  align-items: center;
  width: 90%;
}

@media screen and (max-width: 700px) {
  .festival-container {
    flex-direction: column;
    max-width: 400px;
  }

  .festival-container:nth-child(2),
  .festival-container:nth-child(4) {
    flex-direction: column-reverse;
  }
}

.image-container-festival {
  min-width: 400px;
  max-width: 450px;
  width: 100%;
  max-height: 275px;
  overflow: hidden;
}

.festival-img {
  min-height: 100%;
  min-width: 100%;
  width: 10px;
}

.festival-box {
  padding: 1rem;
}

.festival-container h3 {
  font-size: 2.25rem;
  font-family: "Roboto", sans-serif;
  font-weight: bolder;
}

.festival-container p {
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  font-size: 1.05rem;
}

.festival-btn {
  font-size: 1rem;
  padding: 10px 25px;
  border-radius: 5px;
  background-color: var(--font);
  color: var(--primary);
  cursor: pointer;
  margin-top: 1rem;
  border: none;
}

@media screen and (max-width: 500px) {
  .image-container-festival {
    min-width: 95%;
    max-width: 400px;
    height: 200px;
    overflow: hidden;
  }

  .festival-container h3 {
    font-size: 2rem;
  }

  .festival-container p {
    margin-top: 0;
  }
}

