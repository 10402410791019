section.accomodation{
    padding: 3rem 0;
}

.accomodation-container{
    display: flex;
    align-items: center;
    max-width: 1000px;
    width: 90%;
    margin: 1rem auto;
}

@media screen and (max-width:700px) {
    .accomodation-container{
        flex-direction: column;
        max-width: 400px;
    }

    .accomodation-container:nth-child(2){
        flex-direction: column-reverse;
    }
}

.image-container-accomodation{
    min-width: 400px;
    max-width: 400px;
    height: 250px;
    overflow: hidden;
}


.accomodation-img{
    min-height: 100%;
    min-width: 100%;
    width: 10px;
}

.accomodation-box{
    padding: 1rem;
}

.accomodation-container h3{
    font-size: 2.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
}

.accomodation-container p{
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 1.2rem;
    margin-top: 1.5rem;
}

.accomodation-btn{
    font-size: 1rem;
    padding: 10px 25px;
    border-radius: 5px;
    background-color: var(--font);
    color: var(--primary);
    cursor: pointer; 
    margin-top: 1rem;  
    border: none;
}

@media screen and (max-width:500px) {
    .image-container-accomodation{
        min-width: 95%;
        max-width: 400px;
        height: 200px;
        overflow: hidden;
    }

    .accomodation-container h3{
        font-size: 2rem;
    }

    .accomodation-container p{
        margin-top: 0;
    }
}