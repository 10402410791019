:root{
    --primary: #fff;
    --secondary: rgba(255, 255, 255, 0.699);
    --ternary: black;
    --button:#fe4545;
}

section.destination{
    width:100%;
    text-align: center;
    padding: 2rem 0;
}

section.destination h1{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    margin-top: 3rem;
}

.destination-container{
    display: flex;
    max-width: 1000px;
    width: 90%;
    margin:0 auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.destination-box{
    margin:10px 5px;
    height: 400px;
    padding: 1rem;
    width: 300px;
    box-shadow: -1px -2px 1px #706c6c73, 3px 3px 7px rgba(80, 82, 87, 0.288);
    background-color: var(--primary);
    text-decoration: none;
    color: inherit;
}

.destination-box:nth-child(3){
    margin-bottom: 2rem;
}

.destination-box h4{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    margin-top: 1rem;
}

.img-container{
    width:100%;
    height:200px;
    overflow: hidden;
}

.destination-box img{
    min-width: 100%;
    min-height: 100%;
    width:20px;
}

.destination-box p{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: lighter;
    text-align: left;
}

.more-places-btn{
    padding: 10px 25px;
    border-radius: 5px;
    background-color: var(--head);
    color: var(--primary);
    cursor: pointer;
    text-decoration: none;
    margin-top:1rem;
}