.intro-section{
    width: 90%;
    max-width:1000px;
    margin:0 auto;
    padding: 2rem 0;
}

.intro-section .main-info{
    display: flex;
    align-items: center;
    margin:1rem 0;
}

.intro-section img{
    max-height: 400px;
    max-width: 500px;
    width:90%;
}

.intro-section .info{
    padding: 2rem;
}

.info h1{
    font-family: 'Oswald', sans-serif;
    font-size: 5rem;
}
.info-txt{
    font-family: 'Roboto',sans-serif;
    font-size: 1.2rem;
}
.sources{
    margin-top: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: bold;
}
.sources a{
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}
.info-box-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.info-bar{
    border: 2px solid black;
    padding: 5px 0px 5px 10px;
    width: 200px;
    margin:10px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}

.info-text{
    font-size: 0.75rem;
    display: block;
}

.info-text1{
    font-size: 1.2rem;
    font-weight:900;
}

@media screen and (max-width:900px) {
    .main-info{
        flex-direction: column;
    }
}

@media screen and (max-width:500px) {
    .info-txt{
        font-size:1rem;
    }

    .info h1{
        font-size: 3rem;
    }

    .info-bar{
        width: 100%;
    }
}