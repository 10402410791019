@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
:root{
    --primary: #fff;
    --head: #DC143C;
    --font: #003893;
    --ternary: rgb(7, 7, 7);
    --background: red;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
}
.container{
    min-height: 100vh;
    display: flex;
}
.flex{
    display: flex;
}
.center{
    align-items: center;
    justify-content: center;
}
.column{
    flex-direction: column; 
}
.space-around{
    justify-content: space-around;
}
.middle{
    justify-content: center;
}



